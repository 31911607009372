import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic05 from '../assets/images/inter.jpeg'

const Interceptors = (props) => (
    <Layout>
        <Helmet>
            <title>Interceptors</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        {/* <BannerLanding /> */}

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Interceptors</h2>
                    </header>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <div className="image">
                        <img src={pic05} alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            {/* <header className="major">
                                <h3>Orci maecenas</h3>
                            </header> */}
                            <p>Our range of fuel and oil Interceptors are designed and manufactured in Ireland, they ensure high performance across a range of applications. These full retention Interceptors will treat the whole of the specified flow, minimising risk to the local environment.</p>

                            <p>All our Interceptors are built to order allowing us to cater to your specific requirements.</p>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Interceptors